<template>
  <div class="box">
    <h1>注册 打开资源世界的大门</h1>
    <div class="form-wrapper">
      <user-register></user-register>
    </div>
  </div>
</template>
<script>
export default {
  name: "Register",
  data() {
    return {};
  },
  components: {
    UserRegister: () => import("../../components/user/UserRegister.vue"),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 36px;
  color: #fff;
  line-height: 1.1;
  padding-top: 36px;
  width: 1000px;
  margin: 0 auto;
  letter-spacing: 0;
  font-weight: 600;
}

.form-wrapper {
  height: 480px;
  margin: 72px auto 0;
}
</style>
